import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import adminManagement from "../../utils/validation/maskPolicy";
import {
  useCreateMaskPolicyMutation,
  useGetMaskPolicyDetailQuery,
} from "../../store/feature/service/maskingPolicyApiSlice";
import Swal from "sweetalert2";

const MaskingPolicyField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");
  const [anchorEl, setAnchorEl] = useState(null);
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;

  const [createMaskPolicy, { isSuccess, isError, data, error }] =
    useCreateMaskPolicyMutation();

  const optStatusSelect = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];
  const optRoleSelect = [
    {
      label: "SUPERADMIN",
      value: "SUPERADMIN",
    },
    {
      label: "ADMINISTRATOR",
      value: "ADMINISTRATOR",
    },
    {
      label: "OPERATOR",
      value: "OPERATOR",
    },
  ];

  const { data: dataMaskPolicy } = useGetMaskPolicyDetailQuery(idParam);
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    policyName: string;
    charset: string;
    maskChar: string;
    persSuffix: string;
    persPre: string;
    space: string;
    alphabet: string;
    symbol: string;
    digit: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminManagement),
    defaultValues: {
      policyName: "",
      charset: "",
      maskChar: "",
      persSuffix: "",
      persPre: "",
      space: "",
      alphabet: "",
      symbol: "",
      digit: "",
    },
  });

  const optCharset = [
    {
      label: "Numeric",
      value: "Numeric",
    },
    {
      label: "Any",
      value: "Any",
    },
  ];

  // REGION: SET FORM DATA
  // useEffect(() => {
  //   if (editFgroup) {
  //     reset({
  //       policyName: "",
  //       charset: "",
  //       persSuffix: "",
  //       persPre: "",
  //     });
  //   } else {
  //     reset();
  //   }
  // }, [editDataOcspConfig]);

  // REGION: DISPATCH GET DATA
  useEffect(() => {
    if (dataMaskPolicy) {
      reset({
        policyName: dataMaskPolicy.name,
        charset: dataMaskPolicy.charset.length !== 0 ? dataMaskPolicy.charset : "Any",
        maskChar: dataMaskPolicy.maskChar,
        persSuffix: dataMaskPolicy.preservedSuffixLength,
        persPre: dataMaskPolicy.preservedPrefixLength,
        space: dataMaskPolicy.space,
        alphabet: dataMaskPolicy.alphabet,
        symbol: dataMaskPolicy.symbol,
        digit: dataMaskPolicy.digit,
      });
    }
    // dispatch(getCertificateDropdown({}));
  }, [dataMaskPolicy]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      navigate("/masking-policy");
      // dispatch(removeEventFlag())
    }
  }, [eventFlag]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    console.log("eeee ===> ", e);
    let tempVar = {
      charset: e.charset,
      maskChar: e.maskChar,
      name: e.policyName,
      preservedPrefixLength: Number(e.persPre),
      preservedSuffixLength: Number(e.persSuffix),
    };
    createMaskPolicy(tempVar);
    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  useEffect(() => {
    if (isError) {
      const dataError: any = error;
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: dataError?.data?.error,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Mask Policy has been Created",
      }).then(() => navigate("/masking-policy"));
    }
  }, [isSuccess]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleInfo = (event: any) => {
    // console.log('info brooo ===> ')
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup ? "Detail Masking Policy" : "Create Masking Policy"}
        </Typography>

        <InputForm
          name="policyName"
          label="Policy Name & ID"
          placeholder=" Type Policy Name & ID "
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={12}
          required
          info={handleInfo}
          id={id}
          infoData={true}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
          This field will become a Policy ID, you can enter maximum 20
          characters.
          </Typography>
        </Popover>
        <SelectFormm
          name="charset"
          label="Charset"
          defaultValue={0}
          options={optCharset}
          placeholder="Please Select"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        {/* <SelectFormm
          name="charset"
          label="Charset"
          defaultValue={0}
          options={optRoleSelect}
          placeholder="none"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
        /> */}

        <InputForm
          name="maskChar"
          label="Mask Char"
          placeholder="Type Mask Char"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <InputForm
          name="persSuffix"
          label="Preserved Suffix Length"
          placeholder="Type Preserved Suffix Length"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />

        <InputForm
          name="persPre"
          label="Preserved Prefix Length"
          placeholder="Type Preserved Prefix Length"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />

        {/* <SelectFormm
          name="status"
          label="Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="none"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
        /> */}

        {editFgroup && (
          <>
            <InputForm
              name="space"
              label="Space"
              placeholder="Password"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />

            <InputForm
              name="alphabet"
              label="Alphabet"
              placeholder="Confirm Password"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            {/* <InputForm
              name="persPre"
              label="Perserved Prefix Length"
              placeholder="Phone Number"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            /> */}
            <InputForm
              name="symbol"
              label="Symbol"
              placeholder="Phone Number"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
            <InputForm
              name="digit"
              label="Digit"
              placeholder="Type Digit"
              disabled={editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </>
        )}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/masking-policy")}
          >
            {editFgroup ? "Back" : "Cancel"}
          </Button>
          {/* {!editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )} */}
          {!editFgroup && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default MaskingPolicyField;
