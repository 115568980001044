import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteUserPolicyMutation } from "../../../store/feature/service/userPolicyApiSlice";
import dayjs from "dayjs";
import {
  useDeleteKeyManagementMutation,
  useRotateKeyMutation,
} from "../../../store/feature/service/keyManagementApiSlice";
import { useEffect } from "react";

const KeyManagementTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  totalData,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );

  const [deleteKeyManagement, { data: dataUserPolicy, isSuccess, isError }] =
    useDeleteKeyManagementMutation();
  const [
    rotateKey,
    {
      data: dataRotateKey,
      isSuccess: successRotateKey,
      isError: errorRotateKey,
    },
  ] = useRotateKeyMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/key-management/edit/${params.row.id}`, { state: params.row });
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this Key",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteKeyManagement(params.row.guid);
        }
      });
    };

    const onRotate = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Rotate Confirmation",
        text: "Are you sure Rotate this Key",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          rotateKey(params.row.guid);
        }
      });
      // console.log("button for rotate key ===> ", params.row);
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
          <Button variant="contained" color="info" onClick={onRotate}>
            Rotate Key
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "keyName",
      headerName: "Key Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "chiper",
      headerName: "Chiper",
      flex: 2,
      minWidth: 250,
    },
    // {
    //   field: "revision",
    //   headerName: "Revision",
    //   flex: 2,
    //   minWidth: 250,
    // },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 2,
      minWidth: 250,
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 2,
    //   minWidth: 250,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params: any) => {
    //     return (
    //       <>
    //         {params.row.status !== null ? (
    //           params.row.status.toLowerCase() === 'active' ? (
    //             <Chip label="ACTIVE" color="success" variant="outlined" />
    //           ) : (
    //             <Chip label="INACTIVE" color="warning" variant="outlined" />
    //           )
    //         ) : (
    //           ''
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Deleted",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Key",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Key",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (successRotateKey) {
      Swal.fire({
        icon: "success",
        title: "Success Rotate",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Rotate Key",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [successRotateKey]);

  useEffect(() => {
    if (errorRotateKey) {
      Swal.fire({
        icon: "error",
        title: "Error Rotate",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Rotate Key",
      });
    }
  }, [errorRotateKey]);
  console.log("data ===> ", data);
  // REGION: INIT ROWS
  const rows = data?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    keyName: row.name,
    description: row.description,
    chiper: row.cipher,
    createdAt: dayjs(row.created_date * 1000).format("YYYY-MM-DDTHH:mm:ssZ[Z]"),
    keyid: row.keyid,
    cipher: row.cipher,
    objectType: row.object_type,
    guid: row.guid,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      {/* <PaginationControl
        params={params}
        setParams={setParams}
        total={totalData}
      /> */}
    </>
  );
};

export default KeyManagementTable;
